import { render, staticRenderFns } from "./classEditing.vue?vue&type=template&id=5614c87c&scoped=true&"
import script from "./classEditing.vue?vue&type=script&lang=js&"
export * from "./classEditing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5614c87c",
  null
  
)

export default component.exports